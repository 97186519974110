import React, { useContext, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Button, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faClose } from "@fortawesome/free-solid-svg-icons";
import Questions from "./Questions";
import DataContext from "../../context/DataContext";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/base.css";
import VerificationDataContext from "../../context/VerificationDataContext";
import Swal from "sweetalert2";
import { getLeadDetails, sendQuestionnaireResponse } from "../../utils/verificationAPI";
import preloaderGif from "../../assets/img/preloader-1.gif";
import "./Questionnaire.scss"

const QuestionnaireHome = ({ isQuitLoader, denyPlanResponse }) => {
  const navigate = useNavigate();

  const {
    questionData,
    progressValue,
    progressBar,
    setProgrssBar,
    answeredQuestionsStack,
    setAnsweredQuestionsStack,
    isEligible,
    setIsEligible,
    currentQuestion,
    setCurrentQuestion,
    nextQuestionButtonRef,

    dhWebsiteLeadId,
    dhEligibileStatus, setDhEligibileStatus,
    setDhWebsiteLeadInfo
  } = useContext(DataContext);

  const { leadId, setIsQuestionnaireCompletionDone, isPreLoading, setIsPreLoading, ShowNotification } = useContext(VerificationDataContext);

  const [isVisible, setIsVisible] = useState(true); //Question - Animation
  const [nextStatus, setNextStatus] = useState(false); //Next - Enable/Disable

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(()=>{
    handleSetVisibleQuestion(1)
  },[])

  const navigateBack = () => {
    const lastAnsweredQuestionID = answeredQuestionsStack.pop();
    setAnsweredQuestionsStack(answeredQuestionsStack);
    setIsEligible(true);

    if (lastAnsweredQuestionID !== undefined) {
      handleSetVisibleQuestion(lastAnsweredQuestionID);
    }
  }; 

  // Next Button Click
  const handleButtonClick = (updatedValue) => {

    let questionValue = questionData?.find(item => item?.id === currentQuestion?.id);
    const value = questionValue?.data?.value;

    if ((parseInt(value) < 18 ||
      parseInt(value) > 60) && (questionValue.sub_type) === "age") {
      setCurrentQuestion({
        ...currentQuestion,
        isInvalid: true,
      });
      return;
    }


    if (questionValue?.input_type === "checkbox") {
      setAnsweredQuestionsStack((prevStack) => [
        ...prevStack,
        currentQuestion?.id,
      ]);
      
      if (value?.length > 0 && value.includes("Warfarin")) { // Not eligible API Call
        let waitlistQuestionID = 15
        handleSetVisibleQuestion(waitlistQuestionID)
      } else {
        handleSetVisibleQuestion(currentQuestion?.id + 1)
      }

    } else {
      setAnsweredQuestionsStack((prevStack) => [
        ...prevStack,
        currentQuestion?.id,
      ]);
      if (questionValue?.next_question) {
        handleSetVisibleQuestion(questionValue?.next_question)
      } else {
        let selectedOption = currentQuestion?.options?.find(item => item?.value === value);
        handleSetVisibleQuestion(selectedOption?.next_question)
      }
    }
  }

  const handleSetVisibleQuestion = (questionID) => {

    let questionSelection = questionData?.find(item => item?.id === questionID);

    if (questionSelection?.is_required && !questionSelection?.data?.value) {
      setNextStatus(false);
    } else {
      setNextStatus(true);
    }

    if(questionSelection?.sub_type === "medication" && questionSelection?.is_none_option) {
      setNextStatus(false); 
    }

    if (questionID === 15) {
      //is_eligible: false
      setDhEligibileStatus(false);
      submitQuestionnaire(false);

    } else if (questionID === 16) {

      //is_eligible: true
      setDhEligibileStatus(true);
      // alert("Completed API here...")
      submitQuestionnaire(true);// Questions
      // Swal.fire({
      //   text: "Are you sure to complete the questionnaire?",
      //   showCancelButton: true,
      //   confirmButtonText: "Yes",
      //   confirmButtonColor: "#000000",
      //   cancelButtonText: "Cancel",
      //   reverseButtons: true,
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     submitQuestionnaire(true);// Questions
      //     return;
      //   } else if (result.dismiss === Swal.DismissReason.cancel) {
      //     return;
      //   }
      // });

    } else {
      //Next Question
      setIsVisible(false);
      setTimeout(() => {
        setCurrentQuestion(questionSelection);
        setProgrssBar(progressValue * currentQuestion?.id);
        setIsVisible(true);
      }, 300);

    }

  } 

  const submitQuestionnaire = async (eligibleState) => {
    setIsPreLoading(true);   

    const convertedResponseData = questionData?.slice(0, -2)?.map(item => ({
      id: item.id,
      ref: item?.ref || "",
      heading: item.heading,
      input_type: item.input_type,
      question: item.question,
      data:{
        value: item?.data?.value || ""
      }
      // value: item?.data?.value || ""
    }));

    const requestData = {
      lead_id: dhWebsiteLeadId,
      questionnaire_response: convertedResponseData,
      is_eligible: eligibleState,
    };

    // setTimeout(() => {
    //   navigate("/screening-result");
    //   setIsPreLoading(false);
    // }, 3000)
    
    try {
      const questionnaireResponse = await sendQuestionnaireResponse(requestData);  

      if (questionnaireResponse?.status === "success") {
        navigate("/screening-result");
      }

    } catch (error) {
      console.error("Error fetching data:", error.response ? error.response.data : error.message);            
      ShowNotification("error", error?.message);  
    } finally {
      setIsPreLoading(false);
    }
  }

  return (
    <div className="container questionnaire-container">

      {isPreLoading && (
        <div className="preloader-section">
          <img src={preloaderGif} alt="PreLoading..." />
        </div>
      )}
      
      <header
        className={`questionnaire-header ${isQuitLoader ? "hidden" : ""}`}
      >
        <Button
          variant="outline-dark"
          size="sm"
          className="prev-question hide"
          // disabled={currentQuestionID === 0 ? true : false}
          onClick={() => {
            // if (currentQuestionID === 0) {
            //   // denyPlanResponse();
            // } else {
              handleButtonClick(-1);
            // }
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <div>
          <ProgressBar now={progressBar} label={""} />
        </div>

        <Button
          variant="outline-dark"
          size="sm"
          className="terminate-button"
          // disabled={currentQuestionID === 0 ? true : false}
          onClick={() => {
            // setAlertIsVisible(true);
            Swal.fire({
              text: "Are you sure to close the questionnaire?",
              showCancelButton: true,
              confirmButtonText: "Yes",
              confirmButtonColor: "#000000",
              cancelButtonText: "Cancel",
              reverseButtons: true,
            }).then((result) => {
              if (result.isConfirmed) {
                // navigate("/");
                window.location.href = "https://deep-holistics.webflow.io/";
              } else if (result.dismiss === Swal.DismissReason.cancel) {
              }
            });
          }}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
      </header>

      <div className="questionnaire-block">
        <Questions
          isVisible={isVisible}
          setNextStatus={setNextStatus}
          // handleCallAPI={handleCallAPI}
        />
      </div>

      <footer
        className={`questionnaire-footer ${isVisible ? "fade-in" : "fade-out"} ${isQuitLoader ? "hidden" : ""}`}
      >
        <div className="button-section">
          {currentQuestion?.id !== 1 && (
            <Button
              variant="outline-primary"
              size="sm"
              className="back-question"
              disabled={currentQuestion?.id === 1 ? true : false}
              onClick={() => {
                if (currentQuestion?.id === 1) {
                  //denyPlanResponse(); //First Question
                } else {
                  navigateBack();
                }
              }}
            >
              Back
            </Button>
          )}

          <Button
            variant="outline-primary"
            size="sm"
            className={`next-question ${
              currentQuestion?.id === 1 ? "fullview" : ""
            }`}
            disabled={!nextStatus}
            ref={nextQuestionButtonRef}
            onClick={() => {
              // if (currentQuestion?.id === 14) {
              //   handleCallAPI();
              // } else {
              //   handleButtonClick(1);
              // }
              handleButtonClick(1)
            }}
          >
            {currentQuestion?.id === 14 ? "Finish" : "Next"}
          </Button>
        </div>
      </footer>
    </div>
  );
};

export default QuestionnaireHome;

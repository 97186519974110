import React, { useContext, useEffect, useRef, useState } from 'react'
import "./Verification.scss";
import VerificationDataContext from '../../context/VerificationDataContext';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Form, InputGroup } from 'react-bootstrap';
import { Autocomplete, Checkbox, FormControl, FormControlLabel, InputLabel, TextField, Typography } from "@mui/material";
// import TokenPayment from './TokenPayment';
import { checkUser, getLeadDetails, sendOTP } from '../../utils/verificationAPI';
import { useLocation, useNavigate } from "react-router-dom";
import Select from 'react-select';
import MobileImg from "../../assets/img/icon/mobile.png";
import VerifiedIcon from "../../assets/img/icon/verified-btn.png";
import ActionIcon from "../../assets/img/icon/action-btn.png";

const VerificationIndex = () => {

  const {
    ShowNotification,
    setIsTimeOut,
    setTimeLeft,
    name, setName,
    mobileNumber,
    setMobileNumber,
    countryCode, setCountryCode,
    setOtpSent,
    setHashedMobile,
    setIsPreLoading,
    leadSource,
    countryCodes,
    selectedCountryCode, setSelectedCountryCode,
    tokenLeadId, setTokenLeadId,
    leadInfo, setLeadInfo,
    isWhatsappConsent, setIsWhatsappConsent
  } = useContext(VerificationDataContext);

  /*  */
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    let leadTokenParam = queryParams.get('lead_id')
    setTokenLeadId(leadTokenParam);
  }, [])

  useEffect(() => {

    const fetchLeadDetails = async () => {
      try {
        let requestData = {
          lead_id: tokenLeadId
        }
        const checkLeadDetailsResponse = await getLeadDetails(requestData);
        let responseData = checkLeadDetailsResponse?.data?.lead;
        let countryCodeUpdate = responseData?.country_code?.replace('+', '');
        setLeadInfo(responseData)
        setName(responseData?.name)
        setCountryCode(countryCodeUpdate)
        setMobileNumber(responseData?.mobile)

        if(checkLeadDetailsResponse?.data?.is_paid){
          setIsPreLoading(true);
          setTimeout(() => {
            navigate("/existing-customer");
          }, 500);
        }

      } catch (error) {
        console.error("Error fetching data:", error.response ? error.response.data : error.message);
        setIsPreLoading(false);
        ShowNotification("error", error.message);
      } finally {
      }
    }

    if (tokenLeadId) {
      fetchLeadDetails()
    }
  }, [tokenLeadId])

  //Country code
  const hanldleCountryCodeChange = (selectedOption) => {
    setSelectedCountryCode(selectedOption);
    setShowDropdown(false)
    setTimeout(() => {
      phonenumberRef.current.focus();
    }, 100);
  };

  const handleCheckUser = async (type) => {
    
    if (mobileNumber && name?.trim() && countryCode) {

      setIsPreLoading(true);

      let requestData = {
        country_code: countryCode, //selectedCountryCode?.dial_code.slice(1),
        mobile: mobileNumber,
        name: name,
        lead_id: tokenLeadId,
        lead_source: leadSource || "website",
        is_whatsapp_consent: isWhatsappConsent,
      };

      try {
        const checkUserResponse = await checkUser(requestData);
        let responseLeadID = checkUserResponse?.data?.lead_id;
        // setTokenLeadId(responseLeadID);
        handleSendOTPV1(responseLeadID);
      } catch (error) {
        console.error("Error fetching data:", error.response ? error.response.data : error.message);
        setIsPreLoading(false);
        ShowNotification("error", error.message);
      } finally {
      }
    } else {
      ShowNotification("info", "Please enter valid details");
    }

  };

  const handleSendOTPV1 = async (userLeadID) => {

    if (mobileNumber) {
      // setIsPreLoading(true);
      let requestData = {
        country_code: countryCode,
        mobile: mobileNumber,
        lead_id: userLeadID,
      };

      try {
        const sendOTPResponse = await sendOTP(requestData);

        if (sendOTPResponse?.status === "success") {

          setHashedMobile(sendOTPResponse?.data?.mh);
          setOtpSent(true);

          navigate("/verify-otp"); //Redirect OTP Page

          setTimeout(() => {        
            setIsTimeOut(false);
            setTimeLeft(180);
          }, 900);

        } else if (sendOTPResponse?.status === "error") {

          ShowNotification("error", "Unable Send OTP");

        }
      } catch (error) {
        console.error("Error fetching data:", error.response ? error.response.data : error.message);

        ShowNotification("error", error.message);

      } finally {
        setIsPreLoading(false);
      }
    } else {
      ShowNotification("info", "Please enter valid phone number");
    }

  };

  /*  */

  const [showDropdown, setShowDropdown] = useState(false);
  const selectRef = useRef(null);
  const phonenumberRef = useRef(null);
  const inputGroupRef = useRef(null);

  useEffect(() => {
    if (showDropdown && selectRef.current) {
      selectRef.current.focus();
    }
  }, [showDropdown]);


  useEffect(() => {
    const handleOutsideClick = (event) => {
      const formGroup = document.querySelector('.phone-input');
      const clickedInsideFormGroup = formGroup && formGroup.contains(event.target);

      if (!clickedInsideFormGroup) {
        setShowDropdown(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [showDropdown]);

  const currentYear = new Date().getFullYear();

  return (
    <>
      {/* {verificationID && (
        <div className="testing-expired">
          <h4 className="mb-3">The page has been expired.</h4>
          <h5 className="mb-2" style={{ lineHeight: "1.5" }}>
            Please contact support{" "}
            <a href="mailto:support@deepholistics.com">
              support@deepholistics.com
            </a>
            .
          </h5>
        </div>
      )} */}

      <div className="verification-home">
        <h4 className="welcome-text">Welcome to</h4>
        <h4 className="app-name">Deep Holistics</h4>

        {leadInfo && (
          <>
            <Card className="verification-card mt-4 mb-1"
              onClick={() => {
                handleCheckUser();
              }}>
              <section>
                <div className="left">
                  <div className="type-icon">
                    <img
                      src={MobileImg}
                      alt="verification-icon"
                      loading="lazy"
                      className='mobile'
                    />
                  </div>
                  <div className='content'>
                    <h4>+{`${leadInfo?.country_code ?? ''} ${leadInfo?.mobile ?? ''}`.trim()}</h4>
                    <p>Please verfiy your mobile number</p>
                  </div>
                </div>
                <div className="right">
                  <img
                    src={ActionIcon}
                    alt="action-icon"
                    className="action-icon"
                    loading="lazy"
                  // onClick={()=>{
                  //   handleCheckUser();
                  // }}
                  />
                </div>
              </section>
            </Card>
            <FormControlLabel
              className='whatsapp-consent'
              control={
                <Checkbox onChange={(event) => {
                  setIsWhatsappConsent(event.target.checked)
                }} />}
              label={
                <Typography variant="body2"  sx={{
                  textAlign: "start"
                }}>
                  I agree to receive important notifications, including OTPs and updates, via WhatsApp from <strong>Deep Holistics</strong>.
                </Typography>
              }
              sx={{
                width: "100%",
                paddingLeft: "0.5rem"
              }}
            />
          </>
        )}

        <section className="verification-user-input hide">
          <Form.Group className="mb-3 text-start name-input">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={name}
              onChange={(e) => {
                if (e.target.value?.length <= 50) {
                  setName(e.target.value);
                }
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3 text-start phone-input">
            <Form.Label>Phone</Form.Label>

            <InputGroup className="" ref={inputGroupRef}>
              <InputGroup.Text onClick={() => setShowDropdown(!showDropdown)}>
                {selectedCountryCode?.dial_code}
                {showDropdown ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}

              </InputGroup.Text>
              <Form.Control
                type="tel"
                ref={phonenumberRef}
                value={mobileNumber}
                name="phone"
                onClick={() => {
                  setShowDropdown(false)
                  inputGroupRef.current && inputGroupRef.current.focus();
                }}
                onChange={(e) => {
                  const input = e.target.value;
                  const sanitizedInput = input.replace(/\D/g, "");

                  const maxLength = selectedCountryCode?.code === "IN" ? 10 : 12;

                  if (sanitizedInput.length <= maxLength) {
                    setMobileNumber(sanitizedInput);
                  }
                }}
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    handleCheckUser();
                  }
                }}
              />
            </InputGroup>
            <div className="country-input">
              {showDropdown && (
                <Select
                  ref={selectRef}
                  value={selectedCountryCode?.dial_code}
                  onChange={hanldleCountryCodeChange}
                  options={countryCodes}
                  className="phonenumber-selector"
                  placeholder=""
                  isSearchable
                  menuPlacement="bottom"
                  menuIsOpen={true}
                  defaultMenuIsOpen={true}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      width: "100%",
                      right: 0,
                    }),
                  }}
                />
              )}
            </div>
          </Form.Group>


        </section>

        <section className="verification-footer-section hide">
          {/* <div className='copyright-text'>&copy; IOT Monks Private Limited {currentYear}</div> */}
          <Button
            variant="dark"
            className="send-otp-button"
            disabled={!name?.trim() || !mobileNumber?.trim()}
            style={{ cursor: !name?.trim() || !mobileNumber?.trim() ? 'not-allowed' : 'pointer' }}
            onClick={() => {
              handleCheckUser();
              // handleSendOTPV1()
            }}
          >
            Next
          </Button>
        </section>
      </div>
    </>
  );
}

export default VerificationIndex
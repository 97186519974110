import axios from "axios";

const { REACT_APP_BASE_URL, REACT_APP_CLIENT_ID } = process.env;


const createAxiosInstance = (baseURL) => {
  return axios.create({
    baseURL: baseURL,
    headers: {
      client_id: REACT_APP_CLIENT_ID,
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      "Content-Type": "application/json",
    },
  });
};

const verificationAPI = createAxiosInstance(REACT_APP_BASE_URL);

// const applyAccessTokenInterceptor = (apiInstance) => {
//   apiInstance.interceptors.request.use(
//     function (config) {
//       // config.headers["Cache-Control"] = "no-cache";
//       delete config.headers['Cache-Control'];
//       return config;
//     },
//     function (error) {
//       return Promise.reject(error);
//     }
//   );

//   apiInstance.interceptors.response.use(
//     function (response) {
//       // response.headers["Cache-Control"] = "no-cache";
//       return response;
//     },
//     function (error) {
//       return Promise.reject(error);
//     }
//   );
// };
// applyAccessTokenInterceptor(verificationAPI);
//Get Verification

export const getVerificationInfo = (verificationID) =>

  new Promise((resolve, reject) => {
    verificationAPI
      .get(`/v3/int/human-token/verification/user?verification_id=${verificationID}`)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const sendOTP = (requestData) =>
  new Promise((resolve, reject) => {
    verificationAPI
      .post(`/v3/human-token/users/send-otp`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const verifyOTP = (requestData) =>
  new Promise((resolve, reject) => {

    verificationAPI
      .post(`/v3/human-token/users/verify-otp`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const getRazorepayOrder = (requestData) =>
  new Promise((resolve, reject) => {

    verificationAPI
      .post(`/v3/human-token/payments/orders`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const checkRazorepayPayment = (requestData) =>
  new Promise((resolve, reject) => {

    verificationAPI
      .get(`/v3/human-token/payments/orders`, { params: requestData })
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const checkUser = (requestData) =>
  new Promise((resolve, reject) => {
    verificationAPI
      .post(`/v3/human-token/users/check`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const verifyCoupon = (requestData) =>
  new Promise((resolve, reject) => {
    verificationAPI
      .post(`/v3/human-token/coupon/code/check`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const getLeadDetails = (requestData) =>
    new Promise((resolve, reject) => {
  
      verificationAPI
        .get(`/v3/human-token/lead/get`, { params: requestData })
        .then((response) => {
          resolve({ status: "success", data: response.data.data });
        })
        .catch((error) => {
          reject(error.response?.data);
        });
    });

export const sendQuestionnaireResponse = (requestData) =>
  new Promise((resolve, reject) => {
    verificationAPI
      .patch(`/v3/human-token/lead/eligibility`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const sendQuestionnaireAddress = (requestData) =>
  new Promise((resolve, reject) => {
    verificationAPI
      .post(`/v3/human-token/lead/address`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });

export const sendScheduledEventCallback = (requestData) =>
  new Promise((resolve, reject) => {
    verificationAPI
      .post(`/v3/human-token/lead/email/mark-sent`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data.data });
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });
  
export { verificationAPI };

import "./App.css";
import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
// import HomePage from "./components/HomePage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QuestionnaireHome from "./components/Questionnaire/QuestionnaireHome";
import QuestionnaireSubmission from "./components/Questionnaire/QuestionnaireSubmission";
import NotFoundPage from "./components/error-pages/NotFoundPage";
import BookAppointment from "./components/Questionnaire/BookAppointment";
import Base from "./components/Base";
import PaymentSDK from "./components/Payment/PaymentSDK";
import VerificationIndex from "./components/verification/VerificationIndex";
import OTPVerification from "./components/verification/OTPVerification";
import AddressQuestionnaire from "./components/verification/AddressQuestionnaire";
import DisagreeResult from "./components/verification/DisagreeResult";
import QuestionnaireIndex from "./components/Questionnaire/QuestionnaireIndex";
import ExistingCustomerResult from "./components/verification/ExistingCustomerResult";


function App() {

  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <Routes>
          <Route 
            path="/questionnaire-home" 
            element={<QuestionnaireIndex />} 
          />

          <Route
            path="/questionnaire"
            element={<QuestionnaireHome />}
          />

          <Route
            path="/screening-result"
            element={<QuestionnaireSubmission />}
          />

          <Route
            path="/schedule-appointment"
            element={<BookAppointment />}
          />
          
          {/* Verification */}
          <Route
            element={
              <Base>
                <Outlet />
              </Base>
            }
          >
            <Route path="/" element={<VerificationIndex />} />
            <Route path="/verify-otp" element={<OTPVerification />} />
            <Route path="/address-details" element={<AddressQuestionnaire />} />
            <Route path="/payment" element={<PaymentSDK />} />
            <Route path="/disagree" element={<DisagreeResult />} />
            <Route path="/existing-customer" element={<ExistingCustomerResult />} />
            
          </Route>

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

import React, { useContext, useEffect } from "react";
import VerificationDataContext from "../../context/VerificationDataContext";
import "../Questionnaire/Questionnaire.scss"
import { useNavigate } from "react-router";

const ExistingCustomerResult = () => {

  const navigate = useNavigate();
  const {
    setIsPreLoading
  } = useContext(VerificationDataContext);

  useEffect(()=>{
    setTimeout(() => {
      setIsPreLoading(false);
    }, 500);
  },[])

  return (
    <div className="result-page">

      <div className="result-page-content">
        {/* <h4>
            {"Thank you for your interest in the Human Token"}
          </h4> */}

        <p>
          Looks like you already have an account with Deepholistics. 
          Please continue your journey in the App. 
          <br/>If you do require any assistance, you can contact support{" "} 
          <a
            href="http://deepholistics.com/support"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            here
          </a>{" "}
          or leave us an email at{" "}
          <a
            href="mailto:support@deepholistics.com"
            style={{ textDecoration: "none" }}
          >
            support@deepholistics.com
          </a>
        </p>

        {/* <div className="verification-footer-section">
            <Button
              variant="outline-primary"
              size="sm"
              className={`retake-button`}
              onClick={() => {
                navigate("/address-details");
              }}
            >
              Back to Questionnaire
            </Button>
          </div> */}
      </div>
    </div>
  );
};

export default ExistingCustomerResult;
export const QuestionData = {
    verificationQuestions: [
        {
            id: 21,
            ref: "S_1016",
            heading: "Deep Holistics",
            input_type: "disclaimer",
            question: "Disclaimer",
            sub_type: "disclaimer",
            question_desc:
                "I am aware that while the Human Token provides individualized guidance on health, fitness, sleep, nutrition, and supplements; it cannot diagnose, identify, treat, prevent, or cure any illness. It is advised to speak with a healthcare provider before making any lifestyle adjustments.",
            options: [
                {
                    label: "Agree",
                    value: "agree",
                    next_question: 22,
                },
                {
                    label: "Disagree",
                    value: "disagree",
                    next_question: 25, //not-eligible
                    score: 1,
                },
            ],
            data: {
                value: "",
            },
            is_required: true,
            is_score: true,
        },
        {
            id: 22,
            ref: "S_1019",
            heading: "Deep Holistics",
            input_type: "address",
            question: "What's your shipping address?",
            question_desc: "",
            options: [],
            data: {
                value: {
                    address_line1: "",
                    address_line2: "",
                    city: "",
                    state: "",
                    country: "",
                    zipcode: "",
                },
            },
            validate_message: "Please enter the required details",
            isInvalid: false,
            is_required: true,
            is_score: false,
            next_question: 23,
        },
        {
            id: 23,
            ref: "S_1020",
            heading: "Deep Holistics",
            input_type: "radio",
            sub_type: "",
            question: "Do you wish to use the same as your billing address?",
            question_desc: "",
            options: [
                {
                    label: "Yes",
                    value: "yes",
                    next_question: 26,
                },
                {
                    label: "No",
                    value: "no",
                    next_question: 24,
                },
            ],
            data: {
                value: "",
            },
            is_required: true,
        },
        {
            id: 24,
            ref: "S_1021",
            heading: "Deep Holistics",
            input_type: "address",
            question: "What's your billing address?",
            question_desc: "",
            sub_type: "address2",
            options: [],
            data: {
                value: {
                    address_line1: "",
                    address_line2: "",
                    city: "",
                    state: "",
                    country: "",
                    zipcode: "",
                },
            },
            validate_message: "Please enter the required details",
            isInvalid: false,
            is_required: true,
            is_score: false,
            next_question: 26,
        },
    ],
};

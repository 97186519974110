import React, { useContext, useEffect, useState } from "react";
import "./Verification.scss";
import OTPInput from "react-otp-input";
import { Button } from 'react-bootstrap';
import VerificationDataContext from "../../context/VerificationDataContext";
import { sendOTP, verifyOTP } from "../../utils/verificationAPI";
import { useNavigate } from "react-router";
import DataContext from "../../context/DataContext";

const OTPVerification = ({ type }) => {

  const navigate = useNavigate();

  const {
    verificationObj,
    verificationID,
    timeLeft,
    setTimeLeft,
    isTimeOut,
    setIsTimeOut,
    getVerificationStatus,
    setVerificationLevel,
    setIsLoadingColor,
    setIsLoading,
    setIsLoadingEnd,
    handleSendOTP,
    hashedMobile,
    ShowNotification,
    setIsVerificationLevelDone,
    setIsPaymentLevelDone,
    countryCode,    
    mobileNumber,
    selectedCountryCode,
    leadId,
    setHashedMobile, setIsPreLoading, setOtpSent,
    /*  */
    setIsVerificationCompletionDone,
    setIsQuestionnaireCompletionDone,
    setIsPaymentCompletionDone,
    setIsBetaUserDiscount,
    tokenLeadId,
    setCouponCode,
    setVerifyOTPResponse,
    verificationQuestions,
    setVerificationQuestions
  } = useContext(VerificationDataContext);

  const {setIsEligible} = useContext(DataContext);

  const [otp, setOtp] = useState("");
  const [prevOtp, setPrevOtp] = useState('');

  const [isDisabled, setIsDisabled] = useState(true);
  const [isIncorrectOtp, setIsIncorrectOtp] = useState(false);

  const [isResendOtp, setIsResendOtp] = useState(false);

  useEffect(() => {
    if(!tokenLeadId){
      navigate("/")
    }
  }, [])

  useEffect(() => {
    if (!timeLeft) {
      setIsTimeOut(true);
      return;
    }
    const timer = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const formattedTime = `${Math.floor(timeLeft / 60)}:${(timeLeft % 60 < 10 ? '0' : '')}${timeLeft % 60}`;

  /* Verify */

  const handleVerifyOTP = async () => {

    // setIsLoadingColor("") //Loader Color
    setIsLoading(true);
    
    let requestData = {
      otp: otp,
      mh: hashedMobile
    };

    try {
      const verifyOTPResponse = await verifyOTP(requestData);
      
      if (verifyOTPResponse?.status === "success") {

        // setIsLoadingColor("success")
        setIsLoadingEnd(true);

        let verifyResponseData = verifyOTPResponse?.data

        setIsBetaUserDiscount(verifyOTPResponse?.data?.is_free_user ? true : false) //set for Beta Users
        setVerifyOTPResponse(verifyOTPResponse?.data)
        setCouponCode(verifyOTPResponse?.data?.ht_coupon?.code || null)

        if(verifyResponseData?.pii_user?.communication_address){
          const updatedQuestions = updateAddresses(verificationQuestions, verifyResponseData?.pii_user);
          setVerificationQuestions(updatedQuestions);
        }

        //Existing customer state
        if(verifyResponseData?.pii_user?.customer_id){

          setTimeout(() => {
            navigate("/existing-customer");
            setIsLoading(false);
          }, 1800);

        }else{
          setTimeout(() => {
            navigate("/address-details");
            setIsLoading(false);
  
            setTimeout(() => { 
              // setIsPreLoading(false);
            },0);
  
          }, 1800);
        }
        

        setIsVerificationCompletionDone(true);
      }else if (verifyOTPResponse?.status === "error") {  

        setIsLoading(false);
        setIsIncorrectOtp(true)
      }
    } catch (error) {
      
      console.error("Error fetching data:", error.response ? error.response.data : error.message);
      setIsLoading(false);
      setIsIncorrectOtp(true)
        
      setTimeout(() => {
        setIsLoading(false);
      }, 1800);

    } finally {     
      // navigate("/address-details"); 
    }
  };

  /* Resend OTP */
  const handleResendOTPV1 = async () => {
    
    if(mobileNumber){
      setIsPreLoading(true);
      let requestData = {
        country_code: countryCode,
        mobile: mobileNumber,
        lead_id: tokenLeadId,
      };
  
      try {
        const sendOTPResponse = await sendOTP(requestData);
  
        if (sendOTPResponse?.status === "success") {
          
          setHashedMobile(sendOTPResponse?.data?.mh);
          setOtpSent(true);
          setIsResendOtp(true);

          setTimeout(() => {
            setIsTimeOut(false);
            setTimeLeft(180);
          }, 900);
  
        } else if (sendOTPResponse?.status === "error") {
  
          ShowNotification("error", "Unable Send OTP");
          
        }
      } catch (error) {
        console.error("Error fetching data:", error.response ? error.response.data : error.message);  
        // ShowNotification("error", "Cannot send OTP. Please contact admin");  
      } finally {
        setIsPreLoading(false);
      }
    }else{
      ShowNotification("info", "Please enter valid phone number");
    }
   
  };

  /* Update Address */

  const updateAddresses = (questions, htLead) => {
    return questions?.map((question) => {
      // Update question with id: 22 (shipping address)
      if (question?.id === 22) {
        return {
          ...question,
          data: {
            value: {
              address_line1: htLead?.communication_address?.address_line_1,
              address_line2: htLead?.communication_address?.address_line_2,
              city: htLead?.communication_address?.city,
              state: htLead?.communication_address?.state,
              country: htLead?.communication_address?.country,
              zipcode: htLead?.communication_address?.pincode,
            },
          },
        };
      }
      // Update question with id: 23 (address state)
      if (question?.id === 23) {
        return {
          ...question,
          data: {
            value: htLead?.billing_address?.address_line_1 ? "no" : "yes"
          }
        }
      }
      // Update question with id: 24 (billing address)
      if (question?.id === 24) {
        return {
          ...question,
          data: {
            value: {
              address_line1: htLead?.billing_address?.address_line_1,
              address_line2: htLead?.billing_address?.address_line_2,
              city: htLead?.billing_address?.city,
              state: htLead?.billing_address?.state,
              country: htLead?.billing_address?.country,
              zipcode: htLead?.billing_address?.pincode,
            },
          },
        };
      }

      return question;
    });
  };

  return (
    <section className="otp-verify-section">
      <h4 className="heading">
        Verify your {type === "email" ? "email" : "mobile number"}
      </h4>
      <div className={`verify-icon ${type === "email" ? "email" : "mobile"}`}>

      </div>
      {/* <img
        className={`verify-icon ${type === "email" ? "email" : "mobile"}`}
        src={type === "email" ? EmailOTP : MobileOTP}
        alt="verify-icon"
        loading="lazy"
      /> */}
      <p className="sent-message">An OTP has been sent to +{countryCode}{mobileNumber}{isResendOtp && " via SMS"}</p>
      {/* <p
        style={{
          fontWeight: "500",
          cursor: "pointer",
          marginTop: "5px",
          color: "#0000ff",
        }}
        onClick={() => {
          navigate("/");
        }}
      >
        <FontAwesomeIcon icon={faEdit} className="mx-1" />
        Change phone number
      </p> */}

      <div className={`otp-input-section ${isIncorrectOtp && "incorrect"}`}>
        <OTPInput
          className="input"
          value={otp}
          onChange={(otpValue) => {
            setOtp(otpValue);
            setPrevOtp(otp);

            setIsDisabled(otpValue.length !== 4);

            setIsIncorrectOtp(otpValue === otp);
          }}
          numInputs={4}
          renderSeparator={""}
          renderInput={(props) => <input {...props} />}
          inputType={"tel"}
        />
      </div>

      <div className="verification-footer-section">
        {isIncorrectOtp && <p className="incorrect-otp-text">Incorrect OTP</p>}

        {isTimeOut ? (
          <p
            className="resend-text enable"
            onClick={() => {
              setOtp("");
              setIsDisabled(true);
              setIsIncorrectOtp(false);

              handleResendOTPV1(type);
            }}
          >
            Resend OTP
          </p>
        ) : (
          <p className="resend-text">Resend OTP in {formattedTime}</p>
        )}

        <Button
          variant="outline-success"
          className={`verify-otp`}
          disabled={isDisabled || isIncorrectOtp}
          onClick={handleVerifyOTP}
        >
          Verify OTP
        </Button>
      </div>
    </section>
  );
};


export default OTPVerification
import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getVerificationInfo, sendOTP } from "../utils/verificationAPI";
import countryCodesJson from "../json/country_codes.json";
import { QuestionData } from "../components/json/Questionnaire";

const VerificationDataContext = createContext({});

export const VerificationDataProvider = ({ children }) => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEnd, setIsLoadingEnd] = useState(false);
  const [isPreLoading, setIsPreLoading] = useState(false);

  const [isLoadingColor, setIsLoadingColor] = useState();

  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  const [verificationLevel, setVerificationLevel] = useState("home");  //level

  const [verificationID, setVerificationID] = useState("");
  const [verificationObj, setVerificationObj] = useState([]);

  /*  */
  const [isValidId, setIsValidId] = useState(null);
  const [isCSTBased, setIsCSTBased] = useState(false);
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const [timeLeft, setTimeLeft] = useState();
  const [isTimeOut, setIsTimeOut] = useState(true);

  const [isBetaUserDiscount, setIsBetaUserDiscount] = useState(false)

  const handleVerify = (level) =>{
    setVerificationLevel(level)
  }

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const VidParam = urlParams.get('verification_id');
    setVerificationID(VidParam);
  },[])

  const getVerificationStatus = async () => {
    setIsPreLoading(true);
    try {
      const getVerificationInfoResponse = await getVerificationInfo(verificationID);

      let responseData = getVerificationInfoResponse?.data?.user_detail
      
      setVerificationObj(responseData || []) 
      
      setIsExpired(
        responseData?.verification_expired_at
          ? new Date(responseData.verification_expired_at) < new Date()
          : false
      );

      setIsValidId(true);
      setIsCSTBased(responseData?.source === "CST");
      setIsPaymentDone(responseData?.payment_status==="SUCCESS");
    

    } catch (error) {
      console.error('Error fetching data:', error.response ? error.response.data : error.message);   
      setIsValidId(false);
    } finally {
      setIsPreLoading(false);
    }
  };
  //TYPE_FORM || CST

  useEffect(() => {
    if (verificationID) {
      getVerificationStatus()
    }
  }, [verificationID]);

  const handleSendOTP = async (type) => {
    setIsPreLoading(true);

    let requestData = {
      type: type,
      [type === "email" ? "email" : "mobile"]: verificationObj?.[type],
      verification_id: verificationID
    };

    try {
      const sendOTPResponse = await sendOTP(requestData);

      if (sendOTPResponse?.status === "success") {

        setTimeout(() => {
          setVerificationLevel(type)
          setIsTimeOut(false);
          setTimeLeft(180);
        }, 900);

      } else if (sendOTPResponse?.status === "error") {

        ShowNotification("error", "Unable Send OTP");
        
      }
    } catch (error) {
      console.error("Error fetching data:", error.response ? error.response.data : error.message);

      ShowNotification("error", "Cannot send OTP. Please contact admin");

    } finally {
      setIsPreLoading(false);
    }
  };

  /*  */

  const ShowNotification = (type, message) => {
    toast[type](message, {
      position: "top-right",
      autoClose: 800,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  /* New Flow */
  const [name, setName] = useState("")
  const [countryCode, setCountryCode] = useState("")
  const [mobileNumber, setMobileNumber] = useState("")
  const [isWhatsappConsent, setIsWhatsappConsent] = useState(false)
  
  const [otpSent,setOtpSent]=useState(false)
  const [hashedMobile,setHashedMobile] =useState("")

  const [countryCodes, setCountryCodes] = useState(countryCodesJson);
  const [selectedCountryCode, setSelectedCountryCode] = useState(countryCodes.find(country => country.code === "IN"));
  const [leadSource, setLeadSource] = useState("");
  const [leadId, setLeadId] = useState("");

  const [isVerificationLevelDone, setIsVerificationLevelDone] = useState(false);
  const [isPaymentLevelDone, setIsPaymentLevelDone] = useState(false);

  const [isVerificationCompleted, setIsVerificationCompleted] = useState(false);

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const sourceParam = urlParams.get('source');
    setLeadSource(sourceParam);
  },[])

  useEffect(() => {
    const countryCodesUpdate = countryCodes.map(country => {
      return {
        ...country,
        label: `${country.name} (${country.dial_code})`,
        value: country.dial_code
      };
    });
    setCountryCodes(countryCodesUpdate);
  
  }, []);

  /* Route Management */
  const [isVerificationCompletionDone, setIsVerificationCompletionDone] = useState(false)
  const [isPaymentCompletionDone, setIsPaymentCompletionDone] = useState(false)
  const [isQuestionnaireCompletionDone, setIsQuestionnaireCompletionDone] = useState(false)


  /* New User Flow */

  const [tokenLeadId, setTokenLeadId] = useState(null);
  const [verificationQuestions, setVerificationQuestions] = useState(QuestionData?.verificationQuestions);
  const [currentVerificationQuestion, setCurrentVerificationQuestion] = useState([]);
  const [leadInfo, setLeadInfo] = useState(null);

  const [verifyOTPResponse, setVerifyOTPResponse] = useState(null);
  const [couponCode, setCouponCode] = useState(null);

  return (
    <VerificationDataContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isLoadingEnd, setIsLoadingEnd,
        isLoadingColor, setIsLoadingColor,
        isPreLoading, setIsPreLoading,
        isLoadingComplete,
        setIsLoadingComplete,
        verificationLevel, setVerificationLevel,
        handleVerify,
        verificationObj, setVerificationObj,
        ShowNotification,
        verificationID,
        getVerificationStatus,
        isValidId,
        isCSTBased,
        isPaymentDone,

        timeLeft, setTimeLeft,
        isTimeOut, setIsTimeOut,
        handleSendOTP,
        isExpired,

        /*  */
        name,setName,
        mobileNumber, setMobileNumber,
        countryCode, setCountryCode,
        otpSent,setOtpSent,
        hashedMobile,setHashedMobile,
        leadSource, setLeadSource,
        leadId, setLeadId,
        isWhatsappConsent, setIsWhatsappConsent,        

        countryCodes,
        selectedCountryCode, setSelectedCountryCode,
        isVerificationLevelDone, setIsVerificationLevelDone,
        isPaymentLevelDone, setIsPaymentLevelDone,
        isVerificationCompleted, setIsVerificationCompleted,

        /*  */
        isVerificationCompletionDone, setIsVerificationCompletionDone,
        isPaymentCompletionDone, setIsPaymentCompletionDone,
        isQuestionnaireCompletionDone, setIsQuestionnaireCompletionDone,
        
        /* Beta User */
        isBetaUserDiscount, setIsBetaUserDiscount,

        /* new Flow */
        tokenLeadId, setTokenLeadId,
        verificationQuestions, setVerificationQuestions,
        currentVerificationQuestion, setCurrentVerificationQuestion,
        leadInfo, setLeadInfo,
        couponCode, setCouponCode,
        verifyOTPResponse, setVerifyOTPResponse
      }}
    >
      {children}
    </VerificationDataContext.Provider>
  );
};

export default VerificationDataContext;

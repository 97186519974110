import React, { useContext, useEffect } from "react";
import { Form } from "react-bootstrap";
import DataContext from "../../context/DataContext";
import { animateScroll as scroll } from "react-scroll";
import QuestionIcon from "../../assets/img/question-icon.png";
import DhIcon from "../../assets/img/dh-logo-dark.png";
// import { add, format } from 'date-fns';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Autocomplete, FormControl, TextField } from "@mui/material";
import CountryList from "../../json/CountryList.json";
import StateList from "../../json/StateList.json";
import DistrictList from "../../json/DistrictList.json";

const Questions = ({ isVisible, setNextStatus, handleCallAPI }) => {
  const {
    questionData,
    setQuestionData,
    currentQuestionID,
    isResumeNone,
    currentQuestion,
    setCurrentQuestion,
    nextQuestionButtonRef
  } = useContext(DataContext);


  //Radio options onChange
  const handleRadioInputChange = (currentSelection) => {

    let selectedQuestion = currentQuestion?.options?.find(item => item?.id === currentQuestion?.id);
    
    setCurrentQuestion({
      ...currentQuestion,
      data: { value: currentSelection },
    });

    setQuestionData(
      questionData.map((question, index) => {
        if (question?.id === currentQuestion?.id) {
          return { ...question, data: { value: currentSelection } };
        }
        return question;
      })
    );
    
    if(selectedQuestion?.next_question !== 15){
      setNextStatus(true);
      setTimeout(() => {
        if (nextQuestionButtonRef.current) {
          nextQuestionButtonRef.current.click();
        }
      }, 100)
    }
  };

  //Check Box
  const handleCheckboxInputChange = (isChecked, currentSelection) => {

    let updatedValues = [];

    if (currentQuestion.is_none) {
      currentQuestion.is_none = false;
    }
    
    if (currentSelection !== "None") {
      updatedValues = isChecked
        ? [...currentQuestion.data.value, currentSelection]
        : currentQuestion.data.value.filter((val) => val !== currentSelection);
    }

    const updatedQuestionData = questionData.map((question, index) => {
      if (question?.id === currentQuestion?.id) {
        return { 
          ...question,
          is_none_option: false,
          data: { value: updatedValues } };
      }
      return question;
    });

    setCurrentQuestion({
      ...currentQuestion,
      is_none_option: false,
      data: { value: updatedValues },
    });

    setQuestionData(updatedQuestionData);

    if (currentQuestion.is_required) {
      setNextStatus(updatedValues.length > 0);      
    } else {
      setNextStatus(true);
    }
  }; 

  useEffect(()=>{
    if(currentQuestion?.id === 13 && !currentQuestion?.is_none_option){
      setNextStatus(true);
    }
  },[currentQuestion])

  //Text Field Change
  const handleTextFieldChange = (value) => {
    let updatedValue;

    if (currentQuestion.sub_type === "age") {

      if(value.length < 5){
        setCurrentQuestion({
          ...currentQuestion,
          data: { value: value },
        });
  
        setQuestionData(
          questionData.map((question, index) => {
            if (index === currentQuestionID) {
              return { ...question, data: { value: value } };
            }
            return question;
          })
        );
        
        if (value) {
          setNextStatus(true);
        }else{
          setNextStatus(false);
        }
      }
      
    } else {
      setCurrentQuestion({
        ...currentQuestion,
        data: { value: value },
      });

      setQuestionData(
        questionData.map((question, index) => {
          if (index === currentQuestionID) {
            return { ...question, data: { value: value } };
          }
          return question;
        })
      );

      setNextStatus(true);
    }
  };

  const handleEmailFieldChange = (value) => {
    let isValidEmail = true;
  
    const validateEmail = String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  
    // if (!validateEmail) {
    //   isValidEmail = false;
    // } else if (currentQuestion.sub_type === "email2" && value.toLowerCase() !== firstEmail) {
    //   isValidEmail = false;
    // }
  
    setNextStatus(isValidEmail);
    
    setCurrentQuestion({
      ...currentQuestion,
      data: { value: value.toLowerCase() },
    });
  
    setQuestionData(
      questionData.map((question, index) => {
        if (index === currentQuestionID) {
          return { ...question, data: { value: value.toLowerCase() } };
        }
        return question;
      })
    );
  
    // if (currentQuestion.sub_type === "email1" && isValidEmail) {
    //   setFirstEmail(value.toLowerCase());
    // }
  };

  const handleDisclaimerChange = (value) =>{

    setCurrentQuestion({
      ...currentQuestion,
      data: { value: value },
    });

    setQuestionData(
      questionData.map((question, index) => {
        if (index === currentQuestionID) {
          return { ...question, data: { value: value } };
        }
        return question;
      })      
    );

    setNextStatus(value ? true : false)
  }

  //Address Field Object
  const handleAddressFieldChange = (text, field) => {

    let newStateValue = "";

    let updatedValue = {};  

    if (field === "country") {
      updatedValue ={
        ...currentQuestion.data.value,
        country: text,
        state: "",
        city: ""
      }
    }else if (field === "state") {
      updatedValue ={
        ...currentQuestion.data.value,
        state: text,
        city: ""
      }
    }else if (field === "city") {
      updatedValue ={
        ...currentQuestion.data.value,
        city: text
      }
    } else{
      updatedValue ={
        ...currentQuestion.data.value,
        [field]: text
      }
    }

    const tempCurrentQuestion = ({
      ...currentQuestion,
      data: {
        value: {
          ...currentQuestion.data.value,
          ...updatedValue
        },
      },
    });
    
    setCurrentQuestion(tempCurrentQuestion)

    setQuestionData((prevQuestionData) =>
      prevQuestionData.map((question, index) => {
        if (index === currentQuestionID) {
          return {
            ...question,
            data: {
              value: {
                ...question.data.value,
                ...updatedValue
              },
            },
          };
        }
        return question;
      })
    );
    

    const {
      data: {
        value: { address_line1, city, state, country, zipcode },
      },
    } = tempCurrentQuestion;


    if (
      address_line1.trim() !== "" &&
      city.trim() !== "" &&
      state.trim() !== "" &&
      country.trim() !== "" &&
      zipcode.trim() !== ""
    ) {
      setNextStatus(true);
    }
  };

   //Next Question Change UseEffect
  //  useEffect(() => {
  //    scrollToTop();

  //    let currentQuestion = questionData[currentQuestionID];

  //    if (currentQuestion) {
  //      if (currentQuestion.input_type === "address") {
  //        setNextStatus(true);
  //      } else if (currentQuestion.is_required) {
  //        if (currentQuestion?.is_boolean) {
  //          setNextStatus((currentQuestion?.data?.value).toString());
  //        } else {
  //          setNextStatus((currentQuestion?.data?.value?.length || 0) > 0);
  //        }
  //      } else if (currentQuestion.is_none) {
  //        setNextStatus(false);

  //        if (currentQuestion.is_saved) {
  //          setNextStatus(true);
  //        }
  //      } else if (
  //        currentQuestion.sub_type === "allergy" &&
  //        !currentQuestion.data.value
  //      ) {
  //        setNextStatus(false);
  //      } else {
  //       if(currentQuestion.data.value){
  //         setNextStatus(true); 
  //       }else{
  //         setNextStatus(false);
  //       }
  //      }

  //      if(currentQuestion.input_type === "email"){}

  //      setCurrentQuestion(currentQuestion);
  //    } else {
  //      // alert("Api Here")
  //      handleCallAPI();
  //    }
  //    if (currentQuestion?.data?.value !== undefined) {
  //      setAnsweredQuestionsStack((prevStack) => [
  //        ...prevStack,
  //        currentQuestionID,
  //      ]);
  //    }

  //    setProgrssBar(progressValue * currentQuestionID);
  //  }, [currentQuestionID]);

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 10,
      smooth: "easeInOutQuart",
    });
  };

  /* Country & State Dynamic Change */
  return (
    <div className={`question-block ${isVisible ? "fade-in" : "fade-out"}`}>
      <section className="top-section">
        {currentQuestion && (
          <img
            src={DhIcon}
            alt="Questionnaire-icon"
            className="question-icon"
          />
        )}
        <h4 className="question-type">{currentQuestion?.heading}</h4>
      </section>
      <section className="question-section">
        <h4 className="question-head">{currentQuestion?.question}</h4>
        <h6 className="question-desc">{currentQuestion?.question_desc}</h6>

        {currentQuestion?.sub_type === "disclaimer" ? (
          <section className="disclaimer-link">
            <div>
              <a
                href="https://www.deepholistics.com/terms"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>
            </div>
            <div>
              <a
                href="https://www.deepholistics.com/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </section>
        ) : (
          <></>
        )}
        <div className="questions-options">
          {currentQuestion?.input_type === "text" && (
            <div>
              <TextField
                className="input-field my-2"
                type={currentQuestion?.sub_type === "age" ? "number" : "text"}
                inputMode={
                  currentQuestion?.sub_type === "age" ? "numeric" : "text"
                }
                required
                variant="outlined"
                size="small"
                fullWidth
                value={currentQuestion?.data?.value}
                onChange={(event) => {
                  const value = event.target.value;

                  if (/^[1-9]\d*$/.test(value)) {
                    handleTextFieldChange(value);
                  } else if (value === "") {
                    handleTextFieldChange("");
                  }
                  
                }}
                onKeyDown={(event) => {
                  const invalidKeys = ["e", ".", "-"];

                  if (currentQuestion?.sub_type === "age" && invalidKeys.includes(event.key)) {
                    event.preventDefault();
                  }
                }}
                onKeyUp={(event) => {
                  if (event.key === "Enter" && nextQuestionButtonRef.current) {
                    nextQuestionButtonRef.current.click();
                  }
                }}
              />
              {currentQuestion?.isInvalid && (
                <p className="invalid-text">
                  {currentQuestion?.validate_message}
                </p>
              )}
            </div>
          )}

          {currentQuestion?.input_type === "email" && (
            <div>
              <TextField
                className="input-field my-2"
                type={"email"}
                name="email"
                required
                variant="outlined"
                size="small"
                fullWidth
                value={currentQuestion?.data?.value}
                onChange={(event) => {
                  handleEmailFieldChange(event.target.value);
                }}
                onKeyUp={(event) => {
                  if (event.key === "Enter" && nextQuestionButtonRef.current) {
                    nextQuestionButtonRef.current.click();
                  }
                }}
              />

              {currentQuestion?.isInvalid && (
                <p className="invalid-text">
                  {currentQuestion?.validate_message}
                </p>
              )}
            </div>
          )}

          {currentQuestion?.input_type === "address" && (
            <div>
              {/* {currentQuestion?.isInvalid && (
                <p className="invalid-text">
                  {currentQuestion?.validate_message}
                </p>
              )} */}

              <TextField
                className="input-field my-2"
                label="Address line 1"
                type="text"
                required
                variant="outlined"
                size="small"
                fullWidth
                value={currentQuestion?.data?.value?.address_line1}
                onChange={(event) => {
                  handleAddressFieldChange(event.target.value, "address_line1");
                }}
                error={
                  currentQuestion?.isInvalid &&
                  (currentQuestion?.data?.value?.address_line1).trim() === ""
                    ? true
                    : false
                }
                // helperText="Please enter address line"
              />
              {currentQuestion?.isInvalid &&
                (currentQuestion?.data?.value?.address_line1).trim() === "" && (
                  <p className="invalid-text">Please enter address line</p>
                )}

              <TextField
                className="input-field my-2"
                label="Address line 2"
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                value={currentQuestion?.data?.value?.address_line2}
                onChange={(event) => {
                  handleAddressFieldChange(event.target.value, "address_line2");
                }}
              />

              <FormControl
                fullWidth
                size="small"
                className="input-field my-2"
                error={
                  currentQuestion?.isInvalid &&
                  currentQuestion?.data?.value?.country?.trim() === ""
                    ? true
                    : false
                }
              >
                <Autocomplete
                  size="small"
                  options={CountryList}
                  value={currentQuestion?.data?.value?.country || ""}
                  onChange={(event, value) => {
                    handleAddressFieldChange(value || "", "country");
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option === value || value === ""
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Country" required />
                  )}
                />
              </FormControl>
              {currentQuestion?.isInvalid &&
                (currentQuestion?.data?.value?.country).trim() === "" && (
                  <p className="invalid-text">Please select a country</p>
                )}

              <FormControl
                fullWidth
                size="small"
                className="input-field my-2"
                error={
                  currentQuestion?.isInvalid &&
                  currentQuestion?.data?.value?.state?.trim() === ""
                    ? true
                    : false
                }
                disabled={currentQuestion?.data?.value?.country ? false : true}
              >
                <Autocomplete
                  size="small"
                  options={
                    (currentQuestion?.data?.value?.country &&
                      StateList[
                        CountryList.findIndex(
                          (country) =>
                            country === currentQuestion?.data?.value?.country
                        )
                      ]?.split("|")) ||
                    []
                  }
                  value={currentQuestion?.data?.value?.state || ""}
                  onChange={(event, value) => {
                    handleAddressFieldChange(value || "", "state");
                  }}
                  disabled={
                    currentQuestion?.data?.value?.country ? false : true
                  }
                  // open={currentQuestion?.data?.value?.country ? true : false}
                  isOptionEqualToValue={(option, value) =>
                    option === value || value === ""
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="State" required />
                  )}
                />
              </FormControl>
              {currentQuestion?.isInvalid &&
                (currentQuestion?.data?.value?.state).trim() === "" && (
                  <p className="invalid-text">Please select a state</p>
                )}

              {currentQuestion?.data?.value?.country === "India" && (
                <>
                  <FormControl
                    fullWidth
                    size="small"
                    className="input-field my-2"
                    error={
                      currentQuestion?.isInvalid &&
                      (currentQuestion?.data?.value?.city).trim() === ""
                        ? true
                        : false
                    }
                    disabled={
                      currentQuestion?.data?.value?.state ? false : true
                    }
                  >
                    <Autocomplete
                      size="small"
                      options={
                        DistrictList?.[
                          (currentQuestion?.data?.value?.state).replace(
                            /\s/g,
                            ""
                          )
                        ] || []
                      }
                      value={currentQuestion?.data?.value?.city}
                      onChange={(event, value) => {
                        handleAddressFieldChange(value || "", "city");
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option === value || value === ""
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="City" required />
                      )}
                    />
                  </FormControl>

                  {currentQuestion?.isInvalid &&
                    (currentQuestion?.data?.value?.city).trim() === "" && (
                      <p className="invalid-text">Please select a City</p>
                    )}
                </>
              )}

              {currentQuestion?.data?.value?.country !== "India" && (
                <>
                  <TextField
                    className="input-field my-2"
                    label="City"
                    type="text"
                    required
                    variant="outlined"
                    disabled={
                      currentQuestion?.data?.value?.state ? false : true
                    }
                    size="small"
                    fullWidth
                    value={currentQuestion?.data?.value?.city}
                    onChange={(event) => {
                      handleAddressFieldChange(event.target.value, "city");
                    }}
                    error={
                      currentQuestion?.isInvalid &&
                      (currentQuestion?.data?.value?.city).trim() === ""
                        ? true
                        : false
                    }
                  />
                  {currentQuestion?.isInvalid &&
                    (currentQuestion?.data?.value?.city).trim() === "" && (
                      <p className="invalid-text">Please enter city</p>
                    )}
                </>
              )}

              {/* <TextField
                className="input-field my-2"
                label="State"
                type="text"
                required
                variant="outlined"
                size="small"
                fullWidth
                value={currentQuestion?.data?.value?.state}
                onChange={(event) => {
                  handleAddressFieldChange(event.target.value, "state");
                }}
                error={
                  currentQuestion?.isInvalid &&
                  (currentQuestion?.data?.value?.state).trim() === ""
                    ? true
                    : false
                }
              />
              {currentQuestion?.isInvalid &&
                (currentQuestion?.data?.value?.state).trim() === "" && (
                  <p className="invalid-text">Please enter state</p>
                )} */}

              {/* <TextField
                className="input-field my-2"
                label="Country"
                type="text"
                required
                variant="outlined"
                size="small"
                fullWidth
                value={currentQuestion?.data?.value?.country}
                onChange={(event) => {
                  handleAddressFieldChange(event.target.value, "country");
                }}
                error={
                  currentQuestion?.isInvalid &&
                  (currentQuestion?.data?.value?.country).trim() === ""
                    ? true
                    : false
                }
              />
              {currentQuestion?.isInvalid &&
                (currentQuestion?.data?.value?.country).trim() === "" && (
                  <p className="invalid-text">Please enter country</p>
                )} */}
              <TextField
                className="input-field my-2"
                label="Zipcode"
                type="number"
                required
                variant="outlined"
                size="small"
                fullWidth
                value={currentQuestion?.data?.value?.zipcode}
                onKeyDown={(event) => {
                  if (event.key === "e") {
                    event.preventDefault();
                  }
                }}
                onChange={(event) => {
                  const sanitizedInput = event.target.value.replace(/\D/g, "");
                  handleAddressFieldChange(sanitizedInput, "zipcode");
                  // if (sanitizedInput.length <= 6) {

                  // }
                }}
                error={
                  (currentQuestion?.isInvalid &&
                  (currentQuestion?.data?.value?.zipcode).trim() === "")
                    ? true
                    : false
                }
              />
              {(currentQuestion?.isInvalid &&
                (currentQuestion?.data?.value?.zipcode).trim() === "") && (
                  <p className="invalid-text">
                    Please enter zipcode
                  </p>
                )}
            </div>
          )}

          {currentQuestion?.options?.map((option, index) => (
            <Form.Check
              key={index}
              type={
                currentQuestion?.input_type !== "disclaimer"
                  ? currentQuestion?.input_type
                  : "radio"
              }
              id={`${currentQuestion.id}-option-${index}`}
              className={`${
                (
                  currentQuestion?.input_type === "checkbox"
                    ? currentQuestion.data?.value.includes(option.value) ||
                      (option.value === "None" &&
                        (!currentQuestion.is_none || isResumeNone) &&
                        currentQuestion.data?.value.length === 0)
                    : currentQuestion.data?.value === option.value
                )
                  ? //currentQuestion.data?.value).toString()
                    "selected"
                  : ""
              } ${
                !option.label.split(" : ")[1] ? "single-line" : "double-line"
              } `}
            >
              <Form.Check.Input
                type={
                  currentQuestion?.input_type !== "disclaimer"
                    ? currentQuestion.input_type
                    : "radio"
                }
                // type={currentQuestion.input_type}
                name={
                  currentQuestion?.input_type === "radio"
                    ? "radioOption"
                    : "checkboxOption"
                }
                className={
                  currentQuestion?.input_type === "radio"
                    ? "radioOption"
                    : "checkboxOption"
                }
                value={option?.value}
                checked={
                  currentQuestion?.input_type === "checkbox"
                    ? currentQuestion.data?.value.includes(option.value) ||
                      (option.value === "None" &&
                        (!currentQuestion.is_none || isResumeNone) &&
                        currentQuestion.data?.value.length === 0)
                    : currentQuestion.data?.value === option.value
                }
                onChange={(event) => {
                  const value = event.target.value;
                  const isChecked = event.target.checked;

                  if (currentQuestion?.input_type === "radio") {
                    handleRadioInputChange(value);
                  } else if (currentQuestion?.input_type === "disclaimer") {
                    handleDisclaimerChange(isChecked ? value : "");
                  } else {
                    handleCheckboxInputChange(isChecked, value);
                  }
                }}
              />
              <Form.Check.Label className="label-text">
                <span className="main">
                  {option.label.split(" : ")[0]}
                  {option.label.split(" : ")[1] && (
                    <span className="sub">
                      <br />
                      {option.label.split(" : ")[1]}
                    </span>
                  )}
                </span>
              </Form.Check.Label>
            </Form.Check>
          ))}
        </div>
      </section>
      <ToastContainer />
    </div>
  );
};

export default Questions;

import React, { useContext, useEffect } from "react";
import DataContext from "../../context/DataContext";
import { sendQuestionnaireResponse } from "../../utils/verificationAPI";
import VerificationDataContext from "../../context/VerificationDataContext";
import "../Questionnaire/Questionnaire.scss"
import { useLocation, useNavigate } from "react-router";
import { Button } from "react-bootstrap";

const DisagreeResult = () => {

  const navigate = useNavigate();


  return (
    <div className="result-page">
   
        <div className="result-page-content">
          <h4>
            {"Thank you for your interest in the Human Token"}
          </h4>

          <p>
            You're seeing this page because you disagreed to our terms and
            policies. If you've changed your mind and would like to
            continue, click on the button below. On the other hand, if you'd
            like to talk to us,{" "}
            <a
              href="http://deepholistics.com/support"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              Contact Us
            </a>{" "}
            or leave us an email at{" "}
            <a
              href="mailto:support@deepholistics.com"
              style={{ textDecoration: "none" }}
            >
              support@deepholistics.com
            </a>
          </p>

          <div className="verification-footer-section">
            <Button
              variant="outline-primary"
              size="sm"
              className={`retake-button`}
              onClick={() => {
                navigate("/address-details");
              }}
            >
              Back to Questionnaire
            </Button>
          </div>
        </div>
    </div>
  );
};

export default DisagreeResult;
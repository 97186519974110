import { createContext, useRef, useState } from "react";
import { QuestionData } from "../components/Questionnaire/QuestionData";

const DataContext = createContext({});

export const DataProvider = ({ children }) => {

  const [questionData, setQuestionData] = useState(QuestionData.eligibility_questions); //.slice(0, 28) .filter(Obj=> Obj.is_score)
  const [currentQuestionID, setCurrentQuestionID] = useState(0); // Current Question ID
  const [currentQuestion, setCurrentQuestion] = useState([]);

  const questionDataLength = 14;//questionData.length; //Question Length 
  const progressValue = 100 / questionDataLength; // Initial Progress Value
  const [progressBar, setProgrssBar] = useState(progressValue); // Current Progress Value

  const [dhWebsiteLeadId, setDhWebsiteLeadId] = useState(null)
  const [dhEligibileStatus, setDhEligibileStatus] = useState(null)
  const [dhWebsiteLeadInfo, setDhWebsiteLeadInfo] = useState(null)

  const [scoreData, setScoreData] = useState(QuestionData.score); //Question Score Information  
  const [prevQuestionID, setPrevQuestionID] = useState(0); // Current Question ID
  const [totalScore, setTotalScore] = useState(0); // Current Question ID

  const [apiResponseData, setApiResponseData] = useState([]); // API Response State

  const [isNavigateScore, setIsNavigateScore] = useState(false); // Navigate to Score state
  const [isResume, setIsResume] = useState(false); // Resume State
  const [isResumeNone, setIsResumeNone] = useState(false); // Resume State
  const [answeredQuestionsStack, setAnsweredQuestionsStack] = useState([]); // Stack to store answered question IDs
  const [isEligible, setIsEligible] = useState(true); // Flag
  const [selectedNoToDisclaimer, setSelectedNoToDisclaimer] = useState(false); //

  const [isQuestionnaireCompleted, setIsQuestionnaireCompleted] = useState(false);
  
  const nextQuestionButtonRef = useRef(null)

  const [isShowQuestionnaire, setIsShowQuestionnaire] = useState(true);

  const [isDisagreeState, setIsDisagreeState] = useState(false)
  const [userEmail, setUserEmail] = useState('')

  return (
    <DataContext.Provider
      value={{
        questionData,
        setQuestionData,
        scoreData,
        setScoreData,
        apiResponseData,
        setApiResponseData,
        currentQuestionID,
        setCurrentQuestionID,
        progressBar,
        setProgrssBar,
        progressValue,
        questionDataLength,
        isNavigateScore,
        setIsNavigateScore,
        isResume,
        setIsResume,
        isResumeNone,
        setIsResumeNone,
        prevQuestionID,
        setPrevQuestionID,
        totalScore,
        setTotalScore,
        answeredQuestionsStack,
        setAnsweredQuestionsStack,
        isEligible,
        setIsEligible,
        selectedNoToDisclaimer,
        setSelectedNoToDisclaimer,
        currentQuestion,
        setCurrentQuestion,
        isQuestionnaireCompleted, setIsQuestionnaireCompleted,
        nextQuestionButtonRef,
        isShowQuestionnaire, setIsShowQuestionnaire,
        isDisagreeState, setIsDisagreeState,
        userEmail, setUserEmail,
        /*  */
        dhWebsiteLeadId, setDhWebsiteLeadId,
        dhEligibileStatus, setDhEligibileStatus,
        dhWebsiteLeadInfo, setDhWebsiteLeadInfo
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;

export const QuestionData = {
  "eligibility_questions": [
    {
      "id": 1,
      "heading": "Deep Holistics",
      "input_type": "text",
      "ref": "S_1002",
      // "input_type": "radio",
      "sub_type": "age",
      "question": "What is your age?",
      "question_desc": "",
      "validate_message": "Please enter a valid age ( 18-60 )",
      "isInvalid": false,
      // "options": [
      //   {
      //     "label": "<18",
      //     "value": "<18",
      //     "next_question": 15,
      //     score: 1,
      //   },
      //   {
      //     "label": "18-65",
      //     "value": "18-65",
      //     "next_question": 2
      //   },
      //   {
      //     "label": "66-80",
      //     "value": "66-80",
      //     "next_question": 2
      //   },
      //   {
      //     "label": ">80",
      //     "value": ">80",
      //     "next_question": 15,
      //     score: 1,
      //   }
      // ],
      "data": {
        "value": ""
      },
      "is_required": true,
      "is_score": false,
      "next_question": 2
    },
    {
      "id": 2,
      "heading": "Deep Holistics",
      "ref": "S_1003",
      "input_type": "radio",
      "question": "What is your biological sex?",
      "question_desc": "",
      "options": [
        {
          "label": "Male",
          "value": "Male",
          "next_question": 4
        },
        {
          "label": "Female",
          "value": "Female",
          "next_question": 3
        }
      ],
      "data": {
        "value": ""
      },
      "is_required": true,
      "is_score": false
    },
    {
      "id": 3,
      "heading": "Deep Holistics",
      "input_type": "radio",
      "question": "Are you currently pregnant or breastfeeding?",
      "question_desc": "",
      "options": [
        {
          "label": "Yes",
          "value": "yes",
          "next_question": 15,
          "score": 1
        },
        {
          "label": "No",
          "value": "no",
          "next_question": 4
        }
      ],
      "data": {
        "value": ""
      },
      "is_required": true,
      "is_score": true,
      "parent_key": 2,
      "allowedValues": ["Female"]
    },
    {
      "id": 4,
      "heading": "Deep Holistics",
      "input_type": "radio",
      "question": "Have you been diagnosed with cancer?",
      "question_desc": "",
      "options": [
        {
          "label": "Yes",
          "value": "yes",
          "next_question": 5
        },
        {
          "label": "No",
          "value": "no",
          "next_question": 7
        }
      ],
      "data": {
        "value": ""
      },
      "is_required": true,
      "is_score": false
    },
    {
      "id": 5,
      "heading": "Deep Holistics",
      "input_type": "radio",
      "question": "What is your cancer type?",
      "question_desc": "",
      "options": [
        {
          "label": "Benign",
          "value": "Benign",
          "next_question": 6
        },
        {
          "label": "Malignant",
          "value": "Malignant",
          "next_question": 6
        }
      ],
      "data": {
        "value": ""
      },
      "is_required": true,
      "is_score": false,
      "is_based_skip": true,
      "parent_key": 4,
      "allowedValues": ["yes"],
    },
    {
      "id": 6,
      "heading": "Deep Holistics",
      "input_type": "radio",
      "question": "What is your treatment stage?",
      "question_desc": "",
      "options": [
        {
          "label": "Currently undergoing treatment",
          "value": "Currently undergoing treatment",
          "next_question": 15,
          "score": 1
        },
        {
          "label": "Completed treatment more than 1 year ago",
          "value": "Completed treatment more than 1 year ago",
          "next_question": 7,
          "score": 0
        },
        {
          "label": "Completed treatment less than 1 year ago",
          "value": "Completed treatment less than 1 year ago",
          "next_question": 15,
          "score": 1
        },
        {
          "label": "Decided not to take treatment",
          "value": "Decided not to take treatment",
          "next_question": 15,
          "score": 1
        },
        {
          "label": "Taking alternative medical treatments",
          "value": "Taking alternative medical treatments",
          "next_question": 15,
          "score": 1
        }
      ],
      "data": {
        "value": ""
      },
      "is_required": true,
      "is_score": true,
      "is_based_next": true
    },
    {
      "id": 7,
      "heading": "Deep Holistics",
      "input_type": "radio",
      "sub_type": "allergy",
      "question": "Are you currently taking anticoagulants/blood thinners?",
      "question_desc": "",
      "options": [
        {
          "label": "Yes",
          "value": "yes",
          "next_question": 8
        },
        {
          "label": "No",
          "value": "no",
          "next_question": 9
        }
      ],
      "data": {
        "value": ""
      },
      "is_required": true,
      "is_score": false
    },
    {
      "id": 8,
      "heading": "Deep Holistics",
      "input_type": "checkbox",
      "question": "Which ones are you taking?",
      "question_desc": "",
      "options": [
        {
          "label": "Aspirin",
          "value": "Aspirin",
          "next_question": 9
        },
        {
          "label": "Clopidogrel",
          "value": "Clopidogrel",
          "next_question": 9
        },
        {
          "label": "Warfarin",
          "value": "Warfarin",
          "next_question": 15,
          "score": 1
        }
      ],
      "data": {
        "value": ""
      },
      "is_required": true,
      "is_score": true,
      "is_none_option": false,
      "parent_key": 7,
      "allowedValues": ["yes"]
    },
    {
      "id": 9,
      "heading": "Deep Holistics",
      "input_type": "radio",
      "question": "Have you ever had a heart attack?",
      "question_desc": "",
      "options": [
        {
          "label": "Yes",
          "value": "yes",
          "next_question": 15,
          "score": 1
        },
        {
          "label": "No",
          "value": "no",
          "next_question": 10
        }
      ],
      "data": {
        "value": ""
      },
      "is_required": true,
      "is_score": true
    },
    {
      "id": 10,
      "heading": "Deep Holistics",
      "input_type": "radio",
      "question": "Do you have any congenital heart diseases?",
      "question_desc": "",
      "options": [
        {
          "label": "Yes",
          "value": "yes",
          "next_question": 15,
          "score": 1
        },
        {
          "label": "No",
          "value": "no",
          "next_question": 11
        }
      ],
      "data": {
        "value": ""
      },
      "is_required": true,
      "is_score": true
    },
    {
      "id": 11,
      "heading": "Deep Holistics",
      "input_type": "radio",
      "question": "Do you have liver cirrhosis?",
      "question_desc": "",
      "options": [
        {
          "label": "Yes",
          "value": "yes",
          "next_question": 15,
          "score": 1
        },
        {
          "label": "No",
          "value": "no",
          "next_question": 12
        }
      ],
      "data": {
        "value": ""
      },
      "is_required": true,
      "is_score": true
    },
    {
      "id": 12,
      "heading": "Deep Holistics",
      "input_type": "radio",
      "question": "Do you have chronic kidney disease?",
      "question_desc": "",
      "options": [
        {
          "label": "Yes",
          "value": "yes",
          "next_question": 15,
          "score": 1
        },
        {
          "label": "No",
          "value": "no",
          "next_question": 13
        }
      ],
      "data": {
        "value": ""
      },
      "is_required": true,
      "is_score": true
    },
    {
      "id": 13,
      "heading": "Deep Holistics",
      "input_type": "checkbox",
      "sub_type": "medication",
      "question": "Are you taking medication for any of the following?",
      "question_desc": "(Select all that applies)",
      "options": [
        {
          label: "Epilepsy",
          value: "Epilepsy",
          next_question: 14, //not-eligible
          score: 1,
        },
        {
          label: "Addison's Disease",
          value: "Addison's Disease",
          next_question: 14, //not-eligible
          score: 1,
        },
        {
          label: "Schizophrenia",
          value: "Schizophrenia",
          next_question: 14, //not-eligible
          score: 1,
        },
        {
          label: "Mania",
          value: "Mania",
          next_question: 14, //not-eligible
          score: 1,
        },
        {
          label: "Bipolar",
          value: "Bipolar",
          next_question: 14, //not-eligible
          score: 1,
        },
        {
          label: "Insulin",
          value: "Insulin",
          next_question: 14, //not-eligible
          score: 1,
        },
        {
          label: "None",
          value: "None",
          next_question: 14
        }
      ],
      "data": {
        "value": []
      },
      "is_none": true,
      "is_none_option": true,
      "is_required": true,
      "is_score": true
    },
    {
      "id": 14,
      "heading": "Deep Holistics",
      "input_type": "radio",
      "question": "Have you been diagnosed with HIV?",
      "question_desc": "",
      "options": [
        {
          "label": "Yes",
          "value": "yes",
          "next_question": 15,
          score: 1
        },
        {
          "label": "No",
          "value": "no",
          "next_question": 16
        }
      ],
      "data": {
        "value": ""
      },
      "is_required": true,
      "is_score": false
    },
    {
      "id": 15,
      "heading": "Deep Holistics",
      "input_type": "completed",
      "question": "You are on a wailist",
      "question_desc": "",
      "is_required": false
    },
    {
      "id": 16,
      "heading": "Deep Holistics",
      "input_type": "completed",
      "question": "Please Wait",
      "question_desc": "",
      "is_required": false
    }
  ],
  questions: [
    {
      id: 1,
      ref: "S_1001",
      heading: "Deep Holistics",
      input_type: "text",
      question: "Let's get started with your name",
      question_desc: "",
      options: [],
      data: {
        value: "",
      },
      is_required: true,
      is_score: false,
      next_question: 2,
    },
    {
      id: 2,
      ref: "S_1002",
      heading: "Deep Holistics",
      input_type: "text",
      sub_type: "age",
      question: "What is your age?",
      question_desc: "",
      validate_message: "Please enter a valid age",
      isInvalid: false,
      options: [],
      data: {
        value: "",
      },
      is_required: true,
      is_score: false,
      next_question: 3,
    },
    {
      id: 3,
      ref: "S_1003",
      heading: "Deep Holistics",
      input_type: "radio",
      question: "What is your biological sex?",
      question_desc: "",
      options: [
        {
          label: "Male",
          value: "Male",
          next_question: 5,
        },
        {
          label: "Female",
          value: "Female",
          next_question: 4,
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: false,
    },
    {
      id: 4,
      ref: "S_1004",
      heading: "Deep Holistics",
      input_type: "radio",
      question: "Are you currently pregnant or breastfeeding?",
      question_desc: "",
      options: [
        {
          label: "Yes",
          value: "yes",
          next_question: 17, //not-eligible
          score: 1,
        },
        {
          label: "No",
          value: "no",

          next_question: 5,
        },
      ],
      data: {
        value: "",
      },

      is_required: false,
      is_score: true,
      parent_key: 3,
      allowedValues: ["Female"],
    },
    {
      id: 5, //score
      ref: "S_1005",
      heading: "Deep Holistics",
      input_type: "radio",
      question: "Have you been diagnosed with cancer?",
      question_desc: "",
      options: [
        {
          label: "Yes",
          value: "yes",

          next_question: 6,
        },
        {
          label: "No",
          value: "no",

          next_question: 8,
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: false,
    },
    {
      id: 6, //score
      ref: "S_1006",
      heading: "Deep Holistics",
      input_type: "radio",
      question: "What is your treatment stage?",
      question_desc: "",
      options: [
        {
          label: "Currently undergoing treatment",
          value: "Currently undergoing treatment",
          next_question: 17, //not-eligible
          score: 1,
        },
        {
          label: "Completed treatment more than 1 year ago",
          value: "Completed treatment more than 1 year ago",
          score: 0,
          next_question: 7,
        },
        {
          label: "Completed treatment less than 1 year ago",
          value: "Completed treatment less than 1 year ago",
          next_question: 17, //not-eligible
          score: 1,
        },
        {
          label: "Decided not to take treatment",
          value: "Decided not to take treatment",
          next_question: 17, //not-eligible
          score: 1,
        },
        {
          label: "Taking alternative medical treatments",
          value: "Taking alternative medical treatments",
          next_question: 17, //not-eligible
          score: 1,
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
      parent_key: 5,
      allowedValues: ["yes"],
      is_based_next: true,
    },
    {
      id: 7, //score
      ref: "S_1007",
      heading: "Deep Holistics",
      input_type: "radio",
      question: "What is your cancer type?",
      question_desc: "",
      options: [
        {
          label: "Benign",
          value: "Benign",
          next_question: 8,
        },
        {
          label: "Malignant",
          value: "Malignant",
          next_question: 8,
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: false,
      is_based_skip: true,
      parent_key: 6,
      allowedValues: ["Completed treatment more than 1 year ago"],
    },
    {
      id: 8,
      ref: "S_1008",
      heading: "Deep Holistics",
      input_type: "radio",
      sub_type: "allergy",
      question: "Are you currently taking anticoagulants/blood thinners?",
      question_desc: "",
      options: [
        {
          label: "Yes",
          value: "yes",
          next_question: 9,
        },
        {
          label: "No",
          value: "no",
          next_question: 10,
        },
      ],
      data: {
        value: "",
      },
      is_required: false,
      is_score: false,
    },
    {
      id: 9, //score
      ref: "S_1009",
      heading: "Deep Holistics",
      input_type: "checkbox",
      question: "Which ones are you taking?",
      question_desc: "",
      options: [
        //check
        {
          label: "Aspirin",
          value: "Aspirin",
          next_question: 10,
        },
        {
          label: "Clopidogrel",
          value: "Clopidogrel",
          next_question: 10,
        },
        {
          label: "Warfarin",
          value: "Warfarin",
          next_question: 17, //not-eligible
          score: 1,
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
      is_none_option: false,
      parent_key: 8,
      allowedValues: ["yes"],
    },
    {
      id: 10, //score
      ref: "S_1010",
      heading: "Deep Holistics",
      input_type: "radio",
      question: "Have you ever had a heart attack?",
      question_desc: "",
      options: [
        {
          label: "Yes",
          value: "yes",
          next_question: 17, //not-eligible
          score: 1,
        },
        {
          label: "No",
          value: "no",
          next_question: 11,
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
    },
    {
      id: 11, //score
      ref: "S_1011",
      heading: "Deep Holistics",
      input_type: "radio",
      question: "Do you have any congenital heart diseases?",
      question_desc: "",
      options: [
        {
          label: "Yes",
          value: "yes",

          next_question: 17, //not-eligible
          score: 1,
        },
        {
          label: "No",
          value: "no",

          next_question: 12,
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
    },
    {
      id: 12,
      ref: "S_1012",
      heading: "Deep Holistics",
      input_type: "radio",
      question: "Do you have liver cirrhosis?",
      question_desc: "",
      options: [
        {
          label: "Yes",
          value: "yes",

          next_question: 17, //not-eligible
          score: 1,
        },
        {
          label: "No",
          value: "no",

          next_question: 13,
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
    },
    {
      id: 13, //score
      ref: "S_1013",
      heading: "Deep Holistics",
      input_type: "radio",
      question: "Do you have chronic kidney disease?",
      question_desc: "",
      options: [
        {
          label: "Yes",
          value: "yes",

          next_question: 17, //not-eligible
          score: 1,
        },
        {
          label: "No",
          value: "no",

          next_question: 14,
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
    },
    {
      id: 14,
      ref: "S_1014",
      heading: "Deep Holistics",
      input_type: "checkbox",
      question: "Are you taking medication for any of the following?",
      question_desc: "(Select all that applies)",
      options: [
        {
          label: "Epilepsy",
          value: "Epilepsy",
          next_question: 17, //not-eligible
          score: 1,
        },
        {
          label: "Addison's Disease",
          value: "Addison's Disease",
          next_question: 17, //not-eligible
          score: 1,
        },
        {
          label: "Schizophrenia",
          value: "Schizophrenia",
          next_question: 17, //not-eligible
          score: 1,
        },
        {
          label: "Mania",
          value: "Mania",
          next_question: 17, //not-eligible
          score: 1,
        },
        {
          label: "Bipolar",
          value: "Bipolar",
          next_question: 17, //not-eligible
          score: 1,
        },
        {
          label: "Insulin",
          value: "Insulin",
          next_question: 17, //not-eligible
          score: 1,
        },
        {
          label: "None",
          value: "None",
          next_question: 15,
        },
      ],
      data: {
        value: [],
      },
      is_none: true,
      is_none_option: true,
      is_required: false,
      is_score: false,
    },
    {
      id: 15, //score
      ref: "S_1015",
      heading: "Deep Holistics",
      input_type: "radio",
      question: "Have you been diagnosed with HIV?",
      question_desc: "",
      options: [
        {
          label: "Yes",
          value: "yes",

          next_question: 17, //not-eligible
          score: 1,
        },
        {
          label: "No",
          value: "no",

          next_question: 16,
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
    },
    {
      id: 16,
      ref: "S_1016",
      heading: "Deep Holistics",
      input_type: "disclaimer",
      question: "Disclaimer",
      sub_type: "disclaimer",
      question_desc:
        "I am aware that while the Human Token provides individualized guidance on health, fitness, sleep, nutrition, and supplements; it cannot diagnose, identify, treat, prevent, or cure any illness. It is advised to speak with a healthcare provider before making any lifestyle adjustments.",
      options: [
        {
          label: "Agree",
          value: "agree",
          next_question: 17,
        },
        {
          label: "Disagree",
          value: "disagree",
          next_question: 17, //not-eligible
          score: 1,
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
    },
    {
      id: 17,
      ref: "S_1017",
      heading: "Deep Holistics",
      input_type: "email",
      sub_type: "email1",
      question: "Help us with your email address?",
      question_desc: "",
      options: [],
      data: {
        value: "",
      },
      validate_message: "Please enter a valid email",
      isInvalid: false,
      is_required: true,
      is_score: false,
      next_question: 18,
      score_check: true,
    },
    {
      id: 18,
      ref: "S_1017",
      heading: "Deep Holistics",
      input_type: "email",
      sub_type: "email2",
      question: "Confirm your email address?",
      question_desc: "",
      options: [],
      data: {
        value: "",
      },
      validate_message: "Email address does not match",
      isInvalid: false,
      is_required: true,
      is_score: false,
      next_question: 20,
      score_check: true,
    },
    {
      id: 19,
      ref: "S_1018",
      heading: "Deep Holistics",
      input_type: "text",
      question: "Phone Number",
      question_desc: "",
      options: [],
      data: {
        value: "",
      },
      is_required: true,
      is_score: false,
      next_question: 20,
    },
    {
      id: 20,
      ref: "S_1019",
      heading: "Deep Holistics",
      input_type: "address",
      question: "What's your shipping address?",
      question_desc: "",
      options: [],
      data: {
        value: {
          address_line1: "",
          address_line2: "",
          city: "",
          state: "",
          country: "",
          zipcode: "",
        },
      },
      validate_message: "Please enter the required details",
      isInvalid: false,
      is_required: true,
      is_score: false,
      next_question: 21,
    },
    {
      id: 21,
      ref: "S_1020",
      heading: "Deep Holistics",
      input_type: "radio",
      sub_type: "",
      question: "Do you wish to use the same as your billing address?",
      question_desc: "",
      options: [
        {
          label: "Yes",
          value: "yes",

          next_question: 23,
        },
        {
          label: "No",
          value: "no",

          next_question: 22,
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
    },
    {
      id: 22,
      ref: "S_1021",
      heading: "Deep Holistics",
      input_type: "address",
      question: "What's your billing address?",
      question_desc: "",
      sub_type: "address2",
      options: [],
      data: {
        value: {
          address_line1: "",
          address_line2: "",
          city: "",
          state: "",
          country: "",
          zipcode: "",
        },
      },
      validate_message: "Please enter the required details",
      isInvalid: false,
      is_required: true,
      is_score: false,
      next_question: 23,
    },
    {
      id: 23,
      ref: "S_1022",
      heading: "Deep Holistics",
      input_type: "radio",
      question: "When would you like your first kit to be shipped",
      question_desc: "",
      options: [
        {
          label: "Immediately",
          value: "Immediately",
        },
        {
          label: "After 1 week",
          value: "After 1 week",
        },
        {
          label: "In 2-3 weeks",
          value: "In 2-3 weeks",
        },
        {
          label: "In 3-4 weeks",
          value: "In 3-4 weeks",
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: false,
    },
  ],
  endings: [
    {
      label: "eligible",
      heading: "Deep Holistics",
      text: "Thank you for your interest in your human token",
      sub_text:
        "Your enthusiasm means a lot to us. We'll connect with you once we're ready to support your health journey.",
    },
    {
      label: "not_eligible",
      heading: "Deep Holistics",
      text: "Thank you!",
      sub_text:
        "You will receive a verification link to verify the email and phone number. Post verification, you will get a payment link to make the payment for the token. We'll see you on the other side",
    },
  ],
};

import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { Button } from "react-bootstrap";
import VerificationDataContext from "../../context/VerificationDataContext";
import DataContext from "../../context/DataContext";
import { getLeadDetails } from "../../utils/verificationAPI";

const QuestionnaireIndex = () => {

  const navigate = useNavigate();

  const {
    dhWebsiteLeadId, setDhWebsiteLeadId,
    setDhEligibileStatus,
    setDhWebsiteLeadInfo
  } = useContext(DataContext);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(()=>{
    setDhWebsiteLeadId(queryParams.get('lead_id'));
  },[])

  useEffect(() => {
    const fetchLeadDetails = async () => {
      try {
        let requestData = {
          lead_id: dhWebsiteLeadId
        }
        const checkLeadDetailsResponse = await getLeadDetails(requestData);
        let responseData = checkLeadDetailsResponse?.data?.lead;
        setDhWebsiteLeadInfo(responseData)
        setDhEligibileStatus(responseData?.is_eligible)

      } catch (error) {
        console.error("Error fetching data:", error.response ? error.response.data : error.message);
      } finally {
      }
    }

    if (dhWebsiteLeadId) {
      fetchLeadDetails()
    }
  }, [dhWebsiteLeadId])

  return (
    <div className="result-page">
      <div className="verification-home">
        
        <h4 className="welcome-text">
          {dhWebsiteLeadId ? 
            "Please proceed to answer few questions to find out the eligibility" : 
            "Not a valid session. Please fill out the form on the deepolistics.com website"}
        </h4>
        {/* <h4 className="app-name">Deep Holistics</h4> */}
      </div>
      {/* <div className="mx-3">
        <p>{"Fill out a form to your registration"}</p>
      </div> */}

      {dhWebsiteLeadId && (<div className="verification-footer-section">
        <Button
          variant="outline-primary"
          size="sm"
          className={`retake-button`}
          onClick={() => {
            navigate("/questionnaire");
          }}
        >
          Continue
        </Button>
      </div>)}
    </div>
  );
};

export default QuestionnaireIndex;






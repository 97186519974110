import React, { useContext, useEffect, useRef, useState } from 'react'
import VerificationDataContext from '../../context/VerificationDataContext';
import { Button, ProgressBar } from 'react-bootstrap';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import preloaderGif from "../../assets/img/preloader-1.gif";
import AddressQuestions from './AddressQuestions';
import { sendQuestionnaireAddress } from '../../utils/verificationAPI';


const AddressQuestionnaire = () => {
  const navigate = useNavigate();

  const {
    verificationQuestions, 
    setVerificationQuestions,
    currentVerificationQuestion, 
    setCurrentVerificationQuestion,
    ShowNotification,
    tokenLeadId,
  } = useContext(VerificationDataContext);

  const nextButtonRef = useRef(null)
  const [answeredQuestionsStack, setAnsweredQuestionsStack] = useState([]);
  const [progressBar, setProgressBar] = useState(0);
  const [isPreLoading, setIsPreLoading] = useState(false);

  const [isVisible, setIsVisible] = useState(true); //Question - Animation
  const [nextStatus, setNextStatus] = useState(false); //Next - Enable/Disable

  useEffect(() => {
    setCurrentVerificationQuestion(verificationQuestions[0])
    if(!tokenLeadId){
      navigate("/")
    }
  }, [])

  useEffect(() => {
    let progressValue = (currentVerificationQuestion?.id) - 20;
    setProgressBar(progressValue*25);
  }, [currentVerificationQuestion])

  const navigateBack = () => {
    const lastAnsweredQuestionID = answeredQuestionsStack.pop();
    setAnsweredQuestionsStack(answeredQuestionsStack);

    if (lastAnsweredQuestionID !== undefined) {
      handleSetVisibleQuestion(lastAnsweredQuestionID);
    }
  }; 

  const handleNextButtonClick = () => {

    let questionValue = verificationQuestions?.find(item => item?.id === currentVerificationQuestion?.id);
    const value = questionValue?.data?.value;

    setAnsweredQuestionsStack((prevStack) => [
      ...prevStack,
      currentVerificationQuestion?.id,
    ]);
    
    if(questionValue?.next_question){
      handleSetVisibleQuestion(questionValue?.next_question)
    }else{
      let selectedOption = currentVerificationQuestion?.options?.find(item => item?.value === value);
      handleSetVisibleQuestion(selectedOption?.next_question)
    }
    
  }

  const handleSetVisibleQuestion = (questionID) => {

    if(questionID === 25){
      // alert("Disagree Terms")
      navigate("/disagree")
      return
    }

    if(questionID === 26){
      // alert("Call API its Completed")
      submitAddressQuestionnaire(true);
      // Swal.fire({
      //   text: "Are you sure to finish the questionnaire?",
      //   showCancelButton: true,
      //   confirmButtonText: "Yes",
      //   confirmButtonColor: "#000000",
      //   cancelButtonText: "Cancel",
      //   reverseButtons: true,
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     submitAddressQuestionnaire(true);
      //     return;
      //   } else if (result.dismiss === Swal.DismissReason.cancel) {
      //     return;
      //   }
      // });

      return
    }

    let questionSelection = verificationQuestions?.find(item => item?.id === questionID);
    

    if(questionSelection?.input_type === "address"){
      const {
        data: {
          value: { address_line1,address_line2, city, state, country, zipcode },
        },
      } = questionSelection;
  
      if (
        address_line1.trim() !== "" &&
        // address_line2.trim() !== "" &&
        city.trim() !== "" &&
        state.trim() !== "" &&
        country.trim() !== "" &&
        zipcode.trim() !== ""
      ) {
        setNextStatus(true);
      }else{
        setNextStatus(false);
      }
     
    }else{

      if (questionSelection?.is_required && !questionSelection?.data?.value) {
        setNextStatus(false);
      } else {
        setNextStatus(true);
      }      
    }
    

    setIsVisible(false);
    setTimeout(() => {
      setCurrentVerificationQuestion(questionSelection);
      // setProgrssBar(progressValue * currentQuestion?.id);
      setIsVisible(true);
    }, 300);

  } 

  const submitAddressQuestionnaire = async (eligibleState) => {
    setIsPreLoading(true);   

    const convertedResponseData = verificationQuestions?.map(item => ({
      id: item.id,
      ref: item?.ref,
      question: item.question,
      data:{
        value: item?.data?.value || ""
      }
    }));

    const requestData = {
      lead_id: tokenLeadId,
      questionnaire_response: convertedResponseData,
      is_eligible: eligibleState,
    };

    try {
      const questionnaireAddresssResponse = await sendQuestionnaireAddress(requestData);  

      if (questionnaireAddresssResponse?.status === "success") {
        navigate("/payment");
      }

    } catch (error) {
      console.error("Error fetching data:", error.response ? error.response.data : error.message);            
      ShowNotification("error", error?.message);  
    } finally {
      setIsPreLoading(false);
    }
  }

  return (

    <div className="container questionnaire-container">

      {isPreLoading && (
        <div className="preloader-section">
          <img src={preloaderGif} alt="PreLoading..." />
        </div>
      )}

      <header
        className={`questionnaire-header`}
      >
        <div>
          <ProgressBar now={progressBar} label={""} />
        </div>

        <Button
          variant="outline-dark"
          size="sm"
          className="terminate-button"
          onClick={() => {
            Swal.fire({
              text: "Are you sure to close the questionnaire?",
              showCancelButton: true,
              confirmButtonText: "Yes",
              confirmButtonColor: "#000000",
              cancelButtonText: "Cancel",
              reverseButtons: true,
            }).then((result) => {
              if (result.isConfirmed) {
                // navigate("/");
                window.location.href = "https://deep-holistics.webflow.io/";
              } else if (result.dismiss === Swal.DismissReason.cancel) {
              }
            });
          }}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
      </header>

      <div className="address-questionnaire questionnaire-block">
        <AddressQuestions
          isVisible={isVisible}
          setNextStatus={setNextStatus}
          currentQuestion={currentVerificationQuestion}
          setCurrentQuestion={setCurrentVerificationQuestion}
          questionData={verificationQuestions}
          setQuestionData={setVerificationQuestions}
          nextButtonRef={nextButtonRef}
        />
      </div>

      <footer
        className={`questionnaire-footer ${isVisible ? "fade-in" : "fade-out"}`}
      >
        <div className="button-section">
          {currentVerificationQuestion?.id !== 21 && (
            <Button
              variant="outline-primary"
              size="sm"
              className="back-question"
              disabled={currentVerificationQuestion?.id === 21 ? true : false}
              onClick={() => {
                if (currentVerificationQuestion?.id === 21) {
                } else {
                  navigateBack();
                }
              }}
            >
              Back
            </Button>
          )}

          <Button
            variant="outline-primary"
            size="sm"
            className={`next-question ${currentVerificationQuestion?.id === 21 ? "fullview" : ""
              }`}
            disabled={!nextStatus}
            ref={nextButtonRef}
            onClick={() => {
              handleNextButtonClick(1)
            }}
          >
            {currentVerificationQuestion?.id === 24 ? "Finish" : "Next"}
          </Button>
        </div>
      </footer>
    </div>
  )
}

export default AddressQuestionnaire
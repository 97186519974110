import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import VerificationDataContext from '../../context/VerificationDataContext';
import DataContext from '../../context/DataContext';
import { sendScheduledEventCallback } from '../../utils/verificationAPI';

const BookAppointment = () => {
  const navigate = useNavigate();
  const { dhWebsiteLeadId, dhWebsiteLeadInfo } = useContext(DataContext);
  const { setIsPreLoading } = useContext(VerificationDataContext);

  const [isLoading, setIsLoading] = useState(true); // New state for loader
  const { REACT_APP_CALENDLY_URL } = process.env;

  // Load Calendly script on component mount
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!dhWebsiteLeadId) {
      navigate("/questionnaire-home");
    }
  }, []);

  useEffect(() => {
    const handleEventScheduled = (event) => {

      if (event.data.event === 'calendly.event_type_viewed') {
        setIsLoading(false)
      }

      if (event.data.event === 'calendly.event_scheduled') {
        const responseURI = event?.data?.payload?.event?.uri;
        let responseEventID = responseURI?.split('/').pop();
        sendScheduleEvent(responseEventID);
      }
    };

    window.addEventListener('message', handleEventScheduled);

    return () => {
      window.removeEventListener('message', handleEventScheduled);
    };
  }, []);

  const sendScheduleEvent = async (event_uuid) => {
    setIsPreLoading(true);
    const requestData = {
      lead_id: dhWebsiteLeadId,
      email_type: "meeting_invite_sales",
      event_uuid: event_uuid
    };

    try {
      const questionnaireResponse = await sendScheduledEventCallback(requestData);
    } catch (error) {
      console.error("Error fetching data:", error.response ? error.response.data : error.message);
    } finally {
      setIsPreLoading(false);
    }
  };

  return (
    <section className="schedule-appoinment-section" style={{ position: 'relative', minHeight: 'calc(100vh - 1rem)' }}>
      {isLoading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <div
        className="calendly-inline-widget"
        data-url={`${REACT_APP_CALENDLY_URL}?hide_event_type_details=0&hide_gdpr_banner=1&name=${dhWebsiteLeadInfo?.name}&email=${dhWebsiteLeadInfo?.email}`}
        style={{ minWidth: '320px', height: 'calc(100vh - 1rem)'}}
      ></div>
    </section>
  );
};

export default BookAppointment;

import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../context/DataContext";
import { getLeadDetails } from "../../utils/verificationAPI";
import VerificationDataContext from "../../context/VerificationDataContext";
import preloaderGif from "../../assets/img/preloader-1.gif";

import { useLocation, useNavigate } from "react-router";
import { Button } from "react-bootstrap";

const QuestionnaireSubmission = () => {

  const {
    dhWebsiteLeadId, setDhWebsiteLeadId,
    dhEligibileStatus, setDhEligibileStatus, setDhWebsiteLeadInfo
  } = useContext(DataContext);

  const { isPreLoading, setIsPreLoading } = useContext(VerificationDataContext);

  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const confirmationMessage = 'Are you sure you want to leave? Your changes may not be saved.';
      event.preventDefault();
      return confirmationMessage;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [tempLeadId, setTempLeadId] = useState(null)

  useEffect(()=>{
    if(queryParams.get('lead_id')){
      setTempLeadId(queryParams.get('lead_id'));
    }

    if (!dhWebsiteLeadId && !queryParams.get('lead_id')) {
      navigate('/questionnaire-home');
    }

  },[])

  useEffect(() => {
    const fetchLeadDetails = async () => {
      try {
        setIsPreLoading(true);
        let requestData = {
          lead_id: tempLeadId
        }
        const checkLeadDetailsResponse = await getLeadDetails(requestData);
        let responseData = checkLeadDetailsResponse?.data?.lead;
        setDhWebsiteLeadInfo(responseData)
        setDhWebsiteLeadId(tempLeadId)
        setDhEligibileStatus(responseData?.is_eligible)

      } catch (error) {
        console.error("Error fetching data:", error.response ? error.response.data : error.message);
      } finally {
        setIsPreLoading(false);   
      }
    }

    if (tempLeadId) {
      fetchLeadDetails()
    }
  }, [tempLeadId])

  return (
    <div className="result-page">

      {isPreLoading && (
        <div className="preloader-section">
          <img src={preloaderGif} alt="PreLoading..." />
        </div>
      )}
      
      <div className="result-page-content">

        <h4>{"Thank you for your interest in the Human Token"}</h4>
        {isPreLoading && (<p>Processing</p>)}

        {!isPreLoading &&
          (<>
            {dhEligibileStatus ?
              (<p>
                {/* Get in touch with one of our wellness experts for an overview. {" "} */}
                We will reach out to you over an email to discuss the next steps. {" "}
              </p>) : (
                <p>
                  You're on wailtlist. On the other hand, if you'd
                  like to talk to us,{" "}
                  <a
                    href="http://deepholistics.com/support"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    Contact Us
                  </a>{" "}
                  or leave us an email at{" "}
                  <a
                    href="mailto:support@deepholistics.com"
                    style={{ textDecoration: "none" }}
                  >
                    support@deepholistics.com
                  </a>
                </p>
              )}</>
          )}

        {dhEligibileStatus && (
          <div className="verification-footer-section hide">
            <Button
              variant="outline-primary"
              size="sm"
              className={`retake-button`}
              onClick={() => {
                // setCurrentQuestionID(1);
                // setIsShowQuestionnaire(true);
                navigate("/schedule-appointment");
              }}
            >
              Continue Schedule Appointment
            </Button>
          </div>
        )}

      </div>
    </div>
  );
};

export default QuestionnaireSubmission;
